import { useEffect } from 'react';
import cs from 'classnames';

import { NotifyFeature } from '@features/notify';
import { RouterOutletFeature } from '@features/router-outlet';
import { AppLoaderFeature } from '@features/app-loader';
import { currentUserAction } from '@stores/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';

import styles from './app.module.scss';


const AppComponent = (): JSX.Element => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentUserAction());
  }, [dispatch]);

  const appClassnames = cs(
    styles['app'],
    { [styles['app--is-logged-in']]: auth.isLoggedIn },
  );
  
  return (
    <div className={appClassnames}>
      <div className={styles['app__work-area']}>
        <RouterOutletFeature />
      </div>
      <NotifyFeature />
      <AppLoaderFeature />
    </div>
  );
};

export default AppComponent;
