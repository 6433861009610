import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { HomePage } from '@pages/home';
import { SigninPage } from '@pages/signin';
import { NotFoundPage } from '@pages/not-found';
import { AuthGuardComponent } from '@hocs/auth-guard';
import { AnonGuardComponent } from '@hocs/anon-guard';
import { UserListPage } from '@pages/user-list';
import { UserDetailPage } from '@pages/user-detail';
import { UserCreatePage } from '@pages/user-create';
import { MaterialListPage } from '@pages/material-list';
import { MaterialCreatePage } from '@pages/material-create';
import { MaterialDetailPage } from '@pages/material-detail';


const router = createBrowserRouter([
  { path: '/', element: <AuthGuardComponent><HomePage /></AuthGuardComponent> },
  { path: '/:frameId', element: <AuthGuardComponent><HomePage /></AuthGuardComponent> },
  { path: '/material-list', element: <AuthGuardComponent><MaterialListPage /></AuthGuardComponent> },
  { path: '/material-list/material-create', element: <AuthGuardComponent><MaterialCreatePage /></AuthGuardComponent> },
  { path: '/material-list/material-:id', element: <AuthGuardComponent><MaterialDetailPage /></AuthGuardComponent> },
  { path: '/user-list', element: <AuthGuardComponent><UserListPage /></AuthGuardComponent> },
  { path: '/user-list/user-create', element: <AuthGuardComponent><UserCreatePage /></AuthGuardComponent> },
  { path: '/user-list/user-:id', element: <AuthGuardComponent><UserDetailPage /></AuthGuardComponent> },
  { path: '/signin', element: <AnonGuardComponent><SigninPage /></AnonGuardComponent> },
  { path: '*', element: <NotFoundPage /> },
]);

const RouterOutletFeature = (): JSX.Element => {
  return (
    <RouterProvider router={router} />
  );
};

export { RouterOutletFeature };
