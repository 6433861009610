import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ButtonBackBlock = () => {
  const navigate = useNavigate();

  return (
    <Button
      variant='outlined'
      startIcon={<KeyboardBackspaceIcon />}
      onClick={() => navigate(-1)}
      size="large"
    >
      Назад
    </Button>
  );
};

export { ButtonBackBlock };
