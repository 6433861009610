import { AppBar, Drawer, Fab, IconButton, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import { PanelWelcomeFeature } from '@features/panel-welcome';
import { FrameListFeature } from '@features/frame-list';
import { PanelTopFeature } from '@features/panel-top';
import { PanelBottomFeature } from '@features/panel-bottom';

import styles from './navigation-drawer.module.scss';
import { NavigationDrawerProps } from './navigation-drawer.props';


const NavigationDrawerFeature = ({
  menuOpened,
  setMenuOpened,
  setFrameHandler,
}: NavigationDrawerProps) => {
  return (
    <>
      <div className={styles['navigation-drawer-menu-button']}>
        <Fab onClick={() => setMenuOpened(true)} color="primary" aria-label="add">
          <MenuIcon />
        </Fab>
      </div>
      
      <Drawer
        anchor='bottom'
        open={menuOpened}
        onClose={() => setMenuOpened(false)}
      >
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>REGDS</Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setMenuOpened(false)}
                color="inherit"
              >
                <Close />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div className={styles['navigation-drawer-content']}>
          <div className="container">
            <PanelWelcomeFeature />
            <PanelTopFeature />
            <FrameListFeature setFrameHandler={setFrameHandler} />
            <PanelBottomFeature />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export { NavigationDrawerFeature };
