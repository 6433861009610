import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { useAppSelector } from '@hooks/redux.hooks';
import { roleTranslate } from '@helpers/role-translate.helper';
import { CardDataBlock } from 'src/app/@blocks/card-data';


const UserDetailFeature = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <>
      <TableContainer>
        <Table aria-label="user-detail">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Отчество</TableCell>
              <TableCell>Фамилия</TableCell>
              <TableCell>Электронная почта</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {user.current!.id}
                </TableCell>
                <TableCell>{user.current!.name}</TableCell>
                <TableCell>{user.current!.lastName}</TableCell>
                <TableCell>{user.current!.secondName}</TableCell>
                <TableCell>{user.current!.email}</TableCell>
                <TableCell>{roleTranslate(user.current!.role)}</TableCell>
                <TableCell>{dateTranslate(user.current!.createdAt)}</TableCell>
                <TableCell>{dateTranslate(user.current!.updatedAt)}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {(user.current?.welcomeText || user.current?.topPanelText || user.current?.bottomPanelText) && <CardContent style={{ backgroundColor: '#f0f0f0' }}>
        {user.current?.welcomeText ? (
          <CardDataBlock
            title="Текст приветствия"
            mb
          >
            <CardContent>
              <div className="html-text" dangerouslySetInnerHTML={{ __html: user.current.welcomeText }} />
            </CardContent>
          </CardDataBlock>
        ) : null}

        {user.current?.topPanelText ? (
          <CardDataBlock
            title="Текст верхней панели"
            mb
          >
            <CardContent>
              <div className="html-text" dangerouslySetInnerHTML={{ __html: user.current.topPanelText }} />
            </CardContent>
          </CardDataBlock>
        ) : null}

        {user.current?.bottomPanelText ? (
          <CardDataBlock
            title="Текст нижней панели"
          >
            <CardContent>
              <div className="html-text" dangerouslySetInnerHTML={{ __html: user.current.bottomPanelText }} />
            </CardContent>
          </CardDataBlock>
        ) : null}
      </CardContent>}
    </>
  );
};

export { UserDetailFeature };
