import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { CreateMaterialRequestInterface } from 'src/app/@type/material/create-material-request.interface';
import { MaterialType } from 'src/app/@type/material/material.type';
import { UpdateMaterialRequestInterface } from 'src/app/@type/material/update-material-request.interface';


export class MaterialApi {
  public static async create(
    payload: CreateMaterialRequestInterface,
  ): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('POST', '/material/create', {
      material: payload,
    });
  };

  public static async getList(): Promise<BaseResponseInterface<{ materials: MaterialType[] }>> {
    return await HttpService.sendRequest('GET', '/material/list');
  };

  public static async getOne(id: number): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('GET', '/material/one/' + id);
  };

  public static async update(
    payload: UpdateMaterialRequestInterface,
  ): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('PUT', '/material/update', {
      material: payload,
    });
  };
};
