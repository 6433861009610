import cs from 'classnames';

import { Button, Card, CardActions, CardContent } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import { useAppSelector } from '@hooks/redux.hooks';
import styles from './frame-list.module.scss';
import { FrameListProps } from './frame-list.props';


const FrameListFeature = ({ setFrameHandler }: FrameListProps) => {
  const frame = useAppSelector((state) => state.frame);

  return (
    <div className={cs('row', styles['frame-nav'])}>
      {frame.list.map((item) => (
        <div key={item.id} className={cs('col-md-6', styles['frame-nav__item'])}>
          <Card className={styles['nav-item']}>
            <CardContent className={styles['nav-item__content']}>
              <div className={styles['nav-item__img']}>
                <img src={item.coverSrc} alt="Frame" />
              </div>
              <div className={styles['nav-item__title']}>{item.name}</div>
            </CardContent>
            <CardActions>
              <div className={styles['nav-item__actions']}>
                {item.type === 'frame' ? (
                  <>
                    <Button onClick={() => setFrameHandler(item.id)}>Выбрать</Button>
                    <a href={`/` + item.id} target='_blank' rel="noreferrer">
                      <Button startIcon={<LaunchIcon />}>
                        Открыть в новом окне
                      </Button>
                    </a>
                  </>
                ) : (
                  <a href={item.frameSrc} target='_blank' rel="noreferrer">
                    <Button startIcon={<LaunchIcon />}>
                      Открыть
                    </Button>
                  </a>
                )}
              </div>
            </CardActions>
          </Card>
        </div>
      ))}
    </div>
  );
}

export { FrameListFeature };
