import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';
import { CreateMaterialRequestInterface } from 'src/app/@type/material/create-material-request.interface';
import { MaterialStateInterface } from 'src/app/@type/material/material-state.interface';
import { MaterialType } from 'src/app/@type/material/material.type';
import { UpdateMaterialRequestInterface } from 'src/app/@type/material/update-material-request.interface';

import { MaterialApi } from './material.api';



export const getMaterialListAction = createAsyncThunk<
  MaterialType[],
  undefined,
  { state: { material: MaterialStateInterface } }
>(
  '@@material/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await MaterialApi.getList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.materials;
  },
  {
    condition: (_, { getState }) => {
      const { material } = getState();

      if (material.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getMaterialOneAction = createAsyncThunk<
  MaterialType,
  number,
  { state: { material: MaterialStateInterface } }
>(
  '@@material/get-one',
  async (id: number, { rejectWithValue, dispatch }) => {
    const response = await MaterialApi.getOne(id);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.material;
  },
  {
    condition: (_, { getState }) => {
      const { material } = getState();

      if (material.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createMaterialAction = createAsyncThunk<
  MaterialType,
  CreateMaterialRequestInterface,
  { state: { material: MaterialStateInterface } }
>(
  '@@material/create',
  async (payload: CreateMaterialRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await MaterialApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.material;
  },
  {
    condition: (_, { getState }) => {
      const { material } = getState();

      if (material.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateMaterialAction = createAsyncThunk<
  MaterialType,
  UpdateMaterialRequestInterface,
  { state: { material: MaterialStateInterface } }
>(
  '@@material/update',
  async (payload: UpdateMaterialRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await MaterialApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.material;
  },
  {
    condition: (_, { getState }) => {
      const { material } = getState();

      if (material.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
