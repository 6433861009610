import { SyntheticEvent, useState } from 'react';

import { updateMaterialAction } from '@stores/material/material.actions';
import { FieldBooleanBlock } from '@blocks/field-boolean';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldMultiselectBlock } from '@blocks/field-multiselect';
import { FieldNumberBlock } from '@blocks/field-number';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { MaterialUpdateProps } from './material-update.props';
import styles from './material-update.module.scss';
import { FieldSelectBlock } from 'src/app/@blocks/field-select';


const MaterialUpdateFeature = ({ onDone }: MaterialUpdateProps) => {
  const dispatch = useAppDispatch();
  const [materialState, userState] = useAppSelector((state) => [state.material, state.user]);
  const [formValue, setFormValue] = useState({
    id: materialState.current!.id,
    name: materialState.current!.name,
    type: materialState.current!.type,
    frameSrc: materialState.current!.frameSrc,
    coverSrc: materialState.current!.coverSrc,
    priorityOrder: materialState.current!.priorityOrder ? String(materialState.current!.priorityOrder) : '0',
    plug: materialState.current!.plug,
    users: materialState.current!.users.map((item) => String(item.id)),
  });
  const [formDirty, setFormDirty] = useState(false);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(updateMaterialAction(formValue));

    if (result.type === '@@material/update/fulfilled') {
      onDone();
    }
  }

  const setError = (fieldName: string) => {
    return materialState.errors && materialState.errors[fieldName] && errorTranslate(materialState.errors[fieldName][0]);
  }

  return (
    <div className="row justify-content-sm-center">
      <div className="col col-sm-6">
        <div className={styles['material-update']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
            <FieldTextBlock
              name="name"
              label="Название"
              value={formValue.name}
              onChange={changeFormValue}
              error={setError('name')}
            />

            <FieldSelectBlock
              name="type"
              label="Тип"
              value={formValue.type}
              onChange={changeFormValue}
              error={setError('type')}
              items={[
                { label: 'Фрейм', value: 'frame' },
                { label: 'Ссылка', value: 'link' },
              ]}
            />

            <FieldTextBlock
              name="frameSrc"
              label="Frame src"
              value={formValue.frameSrc}
              onChange={changeFormValue}
              error={setError('frameSrc')}
            />

            <FieldTextBlock
              name="coverSrc"
              label="Изображение"
              value={formValue.coverSrc}
              onChange={changeFormValue}
              error={setError('coverSrc')}
            />

            <FieldBooleanBlock
              name="plug"
              label="Заглушка"
              value={formValue.plug}
              onChange={changeFormValue}
              error={setError('coverSrc')}
            />

            <FieldMultiselectBlock
              name="users"
              label="Пользователи"
              value={formValue.users}
              onChange={changeFormValue}
              error={setError('users')}
              items={userState.list.map((item) => ({ value: String(item.id), label: `${item.name} ${item.lastName || ''}` }))}
            />

            <FieldNumberBlock
              name="priorityOrder"
              label="Приоритет сортировки"
              value={formValue.priorityOrder}
              onChange={changeFormValue}
              error={setError('priorityOrder')}
            />

            <ButtonSaveBlock
              loading={materialState.status === 'submitting'}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={() => onDone()} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { MaterialUpdateFeature };
