import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getMaterialListAction } from '@stores/material/material.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { dateTranslate } from '@helpers/date-translate.helper';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonCreateBlock } from '@blocks/button-create';


const MaterialListFeature = () => {
  const dispatch = useAppDispatch();
  const materialState = useAppSelector((state) => state.material);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMaterialListAction());
  }, [dispatch])

  return (
    <CardDataBlock
      title="Материалы"
      extra={
        <ButtonCreateBlock onClick={() => navigate('/material-list/material-create')} />
      }
      loading={materialState.status === 'loading'}
      noData={materialState.list.length === 0}
    >
      <TableContainer>
        <Table aria-label="materials">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Фрейм</TableCell>
              <TableCell>Пользователи</TableCell>
              <TableCell>Заглушка</TableCell>
              <TableCell>Приоритет</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialState.list.map((item) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell><Link to={'/material-list/material-' + item.id}>{item.name}</Link></TableCell>
                <TableCell>
                  {item.type === 'frame' ? (
                    <a rel="noreferrer" target="_blank" href={'/' + item.id}>Фрейм</a>
                  ) : (
                    <a rel="noreferrer" target="_blank" href={item.frameSrc}>Ссылка</a>
                  )}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {item.users.map((user) => (<Chip key={user.id} label={`${user.name} ${user.lastName || ''}`} />))}
                  </Box>
                </TableCell>
                <TableCell>{item.plug ? 'Да' : 'Нет'}</TableCell>
                <TableCell>{item.priorityOrder}</TableCell>
                <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardDataBlock>
  );
};

export { MaterialListFeature };
