import cs from 'classnames';

import { Card, CardContent, Divider, LinearProgress, Typography } from '@mui/material';

import { TransitionOpacityBlock } from '@blocks/transition-opacity';

import { CardDataProps } from './card-data.props';
import styles from './card-data.module.scss';


const CardDataBlock = ({
  children,
  title,
  extra,
  loading,
  noData,
  mb
}: CardDataProps) => {
  return (
    <Card className={cs(styles['data-card'], { [styles['data-card--mb']]: mb },)}>
      <CardContent className={styles['data-card__header']}>
        <Typography variant="h5">{title}</Typography>
        <div className={styles['data-card__extra']}>
          {extra}
        </div>
      </CardContent>
      
      <Divider />
      {loading && <div className={styles['data-card__loader']}><LinearProgress /></div>}
      {noData && !loading && <div className={styles['data-card__no-data']}>Нет данных для отображения.</div>}
      {!loading && !noData && (
        <TransitionOpacityBlock>
          {children}
        </TransitionOpacityBlock>
      )}

    </Card>
  );
};

export { CardDataBlock };
