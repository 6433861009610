import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';
import { MaterialStateInterface } from 'src/app/@type/material/material-state.interface';
import { MaterialType } from 'src/app/@type/material/material.type';

import { FrameApi } from './frame.api';



export const getMaterialListByUserAction = createAsyncThunk<
  MaterialType[],
  undefined,
  { state: { material: MaterialStateInterface } }
>(
  '@@material/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await FrameApi.getListByUser();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.materials;
  },
  {
    condition: (_, { getState }) => {
      const { material } = getState();

      if (material.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);
