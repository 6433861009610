import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Face } from '@mui/icons-material';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { signoutAction } from '@stores/auth/auth.actions';

import { TopBarProps } from './top-bar.props';
import styles from './top-bar.module.scss';

const TopBarFeature: FC<TopBarProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const frame = useAppSelector((state) => state.frame);
  const auth = useAppSelector((state) => state.auth);
  const dispath = useAppDispatch();
  const navigate = useNavigate();
  
  const currentFrame = frame.list.find((item) => item.id === frame.current);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    dispath(signoutAction());
    handleClose();
  }
  
  return (

    <div className={styles['top-bar']}>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            REGDS: {currentFrame ? currentFrame.name : null}
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Face />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {auth.user?.role === 'admin' && <MenuItem onClick={() => navigate('/user-list')}>Пользователи</MenuItem>}
              {auth.user?.role === 'admin' && <MenuItem onClick={() => navigate('/material-list')}>Материалы</MenuItem>}
              <MenuItem onClick={handleSignout}>Выйти</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { TopBarFeature };
