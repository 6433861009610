import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { LayoutBaseFeature } from '@features/layout-base';
import { setCurrentFrame } from '@stores/frame/frame.slice';
import { getMaterialListByUserAction } from '@stores/frame/frame.actions';
import { NavigationDrawerFeature } from '@features/navigation-drawer';

import styles from './home.module.scss';
import { HomePageParams } from './home.page-params';


const HomePage = () => {
  const { frameId } = useParams<HomePageParams>();

  const frame = useAppSelector((state) => state.frame);
  const currentFrame = useAppSelector((state) => state.frame.list.find((item) => item.id === state.frame.current));
  const dispatch = useAppDispatch();

  const [menuOpened, setMenuOpened] = useState(frameId ? false : true);
  // const [loading, setLoading] = useState(true);


  const setFrameHandler = (id: number) => {
    if (id === frame.current) {
      setMenuOpened(false);
      return;
    }

    dispatch(setCurrentFrame(id));
    // setLoading(true);
    setMenuOpened(false);
  };

  useEffect(() => {
    document.getElementById('iframe')?.addEventListener('load', () => {
      // setTimeout(() => void setLoading(false), 2000);
    }, true)

    dispatch(getMaterialListByUserAction())
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(
    () => {
      if (frameId) {
        setFrameHandler(Number(frameId));
      }
    },
    // eslint-disable-next-line
    [frameId],
  );

  return (
    <LayoutBaseFeature withoutBreadcrumbs>
      {frame.status === 'loading' && <div className={styles['home-page__loader']}><CircularProgress /></div>}
      {frame.status === 'idle' && frame.list.length === 0 && <div className={styles['home-page__loader']}>Нет доступных фреймов</div>}
      
      {frame.status === 'idle' && frame.list.length !== 0 && currentFrame && (
        <div className={styles['home-page']}>
          {/* {loading && <div className={styles['home-page__loader']}><CircularProgress /></div>} */}
          <iframe id="iframe" title={currentFrame.name} style={{height: 'calc(99vh - 64px)', width: '100vw'}} src={currentFrame.frameSrc}></iframe>
          
          {currentFrame.plug && <div className={styles['home-page__plug']}>Визуализация данных предоставлена компанией&nbsp;<a rel="noreferrer" href="https://agdrv.ru" target="_blank">Analytics Group</a>.</div>}
          
          <NavigationDrawerFeature
            menuOpened={menuOpened}
            setMenuOpened={setMenuOpened}
            setFrameHandler={setFrameHandler}
          />
        </div>
      )}
    </LayoutBaseFeature>
  );
};

export { HomePage };
