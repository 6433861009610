import cs from 'classnames';

import { Card, CardContent } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';

import styles from './panel-bottom.module.scss';


const PanelBottomFeature = () => {
  const currentUser = useAppSelector((state) => state.auth.user);

  return currentUser?.bottomPanelText ? (
    <div className={cs('row', styles['panel-bottom'])}>
      <div className="col">
        <Card>
          <CardContent>
            {<div className="html-text" dangerouslySetInnerHTML={{ __html: currentUser!.bottomPanelText }} />}
          </CardContent>
        </Card>
      </div>
    </div>
  ) : null;
}

export { PanelBottomFeature };