import cs from 'classnames';

import { Card, CardContent, Typography } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';

import styles from './panel-welcome.module.scss';


const PanelWelcomeFeature = () => {
  const currentUser = useAppSelector((state) => state.auth.user);
  const frame = useAppSelector((state) => state.frame);
  
  const getGreeting = (): string => {
    const hours = new Date().getHours();
    
    if (hours >= 0 && hours < 6) return 'Доброй ночи';
    if (hours >= 6 && hours < 12) return 'Доброе утро';
    if (hours >= 12 && hours < 18) return 'Добрый день';
    if (hours >= 18 && hours <= 23) return 'Доброй ночи';
    
    return '';
  }

  return (
    <div className={cs('row', styles['panel-welcome'])}>
      <div className="col">
        <Card>
          <CardContent>
            <Typography mb={1} variant="h5">{getGreeting()}, {currentUser?.name + (currentUser?.secondName ? ` ${currentUser?.secondName}` : '')}!</Typography>
            <Typography variant="body1">Вам доступно {frame.list.length} материалов для работы.</Typography>

            {currentUser?.welcomeText ? <div className="html-text" dangerouslySetInnerHTML={{ __html: currentUser!.welcomeText }} /> : ''}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export { PanelWelcomeFeature };