import cs from 'classnames';

import { TransitionOpacityBlock } from '@blocks/transition-opacity';
import { BreadcrumbsFeature } from '@features/breadcrumbs';
import { useAppSelector } from 'src/app/@hooks/redux.hooks';
import { TopBarFeature } from '../top-bar';

import { LayoutBaseProps } from './layout-base.props';


const LayoutBaseFeature = ({ children, withoutBreadcrumbs }: LayoutBaseProps) => {
  const auth = useAppSelector((state) => state.auth);

  return (
    <>
      {auth.isLoggedIn && <TopBarFeature />}
      <div className={cs({ 'container-fluid': !withoutBreadcrumbs },)}>
        {!withoutBreadcrumbs && <BreadcrumbsFeature />}
        <TransitionOpacityBlock>
          <div style={{ paddingBottom: '50px' }}>
            {children}
          </div>
        </TransitionOpacityBlock>
      </div>
    </>
  );
}

export { LayoutBaseFeature };
