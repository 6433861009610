import { SyntheticEvent, useState } from 'react';

import { updateUserAction } from '@stores/user/user.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { FieldTextBlock } from '@blocks/field-text';
import { FieldSelectBlock } from '@blocks/field-select';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonSaveBlock } from '@blocks/button-save';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { UserUpdateProps } from './user-update.props';
import styles from './user-update.module.scss';
import { FieldLongTextBlock } from 'src/app/@blocks/field-long-text';


const UserUpdateFeature = ({ onDone }: UserUpdateProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [formValue, setFormValue] = useState({
    id: user.current!.id,
    name: user.current!.name,
    lastName: user.current!.lastName ? user.current!.lastName : '',
    secondName: user.current!.secondName ? user.current!.secondName : '',
    email: user.current!.email,
    role: user.current!.role,
    welcomeText: user.current!.welcomeText ? user.current!.welcomeText : '',
    topPanelText: user.current!.topPanelText ? user.current!.topPanelText : '',
    bottomPanelText: user.current!.bottomPanelText ? user.current!.bottomPanelText : '',
  });
  const [formDirty, setFormDirty] = useState(false);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(updateUserAction(formValue));

    if (result.type === '@@user/update/fulfilled') {
      onDone();
    }
  }

  const setError = (fieldName: string) => {
    return user.errors && user.errors[fieldName] && errorTranslate(user.errors[fieldName][0]);
  }

  return (
    <div className="row justify-content-sm-center">
      <div className="col col-sm-6">
        <div className={styles['user-update']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
            <FieldTextBlock
              name="name"
              label="Имя"
              value={formValue.name}
              onChange={changeFormValue}
              error={setError('name')}
            />

            <FieldTextBlock
              name="secondName"
              label="Отчество"
              value={formValue.secondName}
              onChange={changeFormValue}
              error={setError('secondName')}
            />

            <FieldTextBlock
              name="lastName"
              label="Фамилия"
              value={formValue.lastName}
              onChange={changeFormValue}
              error={setError('lastName')}
            />

            <FieldTextBlock
              name="email"
              label="Электронная почта"
              value={formValue.email}
              onChange={changeFormValue}
              error={setError('email')}
            />

            <FieldSelectBlock
              name="role"
              label="Роль"
              value={formValue.role}
              onChange={changeFormValue}
              error={setError('role')}
              items={[{ value: 'admin', label: 'Администратор' }, { value: 'user', label: 'Пользователь' }]}
            />

            <FieldLongTextBlock
              name="welcomeText"
              label="Текст в блоке приветствия"
              value={formValue.welcomeText}
              onChange={changeFormValue}
              error={setError('welcomeText')}
            />
    
            <FieldLongTextBlock
              name="topPanelText"
              label="Текст в верхнем блоке"
              value={formValue.topPanelText}
              onChange={changeFormValue}
              error={setError('topPanelText')}
            />
    
            <FieldLongTextBlock
              name="bottomPanelText"
              label="Текст в нижнем блоке"
              value={formValue.bottomPanelText}
              onChange={changeFormValue}
              error={setError('bottomPanelText')}
            />

            <ButtonSaveBlock
              loading={user.status === 'submitting'}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={() => onDone()} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { UserUpdateFeature };
