import { useEffect } from 'react';

import { CardDataBlock } from '@blocks/card-data';
import { LayoutBaseFeature } from '@features/layout-base'
import { MaterialCreateFeature } from '@features/material-create';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { ButtonBackBlock } from '@blocks/button-back';
import { getUserListAction } from '@stores/user/user.actions';


const MaterialCreatePage = () => {
  const dispatch = useAppDispatch();
  const materialState = useAppSelector((state) => state.material);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Материалы', link: '/material-list' },
      { title: 'Добавить материал', link: '' },
    ]));
    
    dispatch(getUserListAction());
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <CardDataBlock
            title="Добавить материал"
            extra={<ButtonBackBlock />}
            loading={materialState.status === 'loading'}
          >
            <div className="row justify-content-sm-center">
              <div className="col col-sm-6">
                <MaterialCreateFeature />
              </div>
            </div>
          </CardDataBlock>
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { MaterialCreatePage };
