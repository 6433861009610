import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getUserListAction } from '@stores/user/user.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { dateTranslate } from '@helpers/date-translate.helper';
import { roleTranslate } from '@helpers/role-translate.helper';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonCreateBlock } from '@blocks/button-create';


const UserListFeature = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserListAction());
  }, [dispatch])

  return (
    <CardDataBlock
      title="Пользователи"
      extra={
        <ButtonCreateBlock onClick={() => navigate('/user-list/user-create')} />
      }
      loading={user.status === 'loading'}
      noData={user.list.length === 0}
    >
      <TableContainer>
        <Table aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Электронная почта</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Отчество</TableCell>
              <TableCell>Фамилия</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.list.map((item) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell><Link to={'/user-list/user-' + item.id}>{item.email}</Link></TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.secondName}</TableCell>
                <TableCell>{roleTranslate(item.role)}</TableCell>
                <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardDataBlock>
  );
};

export { UserListFeature };
