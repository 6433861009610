import cs from 'classnames';

import { Card, CardContent } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';

import styles from './panel-top.module.scss';


const PanelTopFeature = () => {
  const currentUser = useAppSelector((state) => state.auth.user);

  return currentUser?.topPanelText ? (
    <div className={cs('row', styles['panel-top'])}>
      <div className="col">
        <Card>
          <CardContent>
            {<div className="html-text" dangerouslySetInnerHTML={{ __html: currentUser!.topPanelText }} />}
          </CardContent>
        </Card>
      </div>
    </div>
  ) : null;
}

export { PanelTopFeature };