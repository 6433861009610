import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createUserAction } from '@stores/user/user.actions';
import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonBackBlock } from '@blocks/button-back';
import { FieldPasswordBlock } from '@blocks/field-password';
import { FieldSelectBlock } from '@blocks/field-select';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { UserType } from '@type/user/user.type';

import styles from './user-create.module.scss';
import { FieldLongTextBlock } from 'src/app/@blocks/field-long-text';


const UserCreateFeature = () => {
  const [formValue, setFormValue] = useState({
    name: '',
    lastName: '',
    secondName: '',
    email: '',
    password: '',
    role: '',
    welcomeText: '',
    topPanelText: '',
    bottomPanelText: '',
  });
  const [formDirty, setFormDirty] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(createUserAction(formValue));

    if (result.type === '@@user/create/fulfilled') {
      navigate('/user-list/user-' + (result.payload as UserType).id);
    }
  }

  const setError = (fieldName: string) => {
    return userState.errors && userState.errors[fieldName] && errorTranslate(userState.errors[fieldName][0]);
  }
  
  return (
    <div className={styles['user-create']}>
      <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
        <FieldTextBlock
          name="name"
          label="Имя"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
        />

        <FieldTextBlock
          name="secondName"
          label="Отчество"
          value={formValue.secondName}
          onChange={changeFormValue}
          error={setError('secondName')}
        />

        <FieldTextBlock
          name="lastName"
          label="Фамилия"
          value={formValue.lastName}
          onChange={changeFormValue}
          error={setError('lastName')}
        />

        <FieldTextBlock
          name="email"
          label="Электронная почта"
          value={formValue.email}
          onChange={changeFormValue}
          error={setError('email')}
        />

        <FieldPasswordBlock
          name="password"
          label="Пароль"
          value={formValue.password}
          onChange={changeFormValue}
          error={setError('password')}
        />

        <FieldSelectBlock
          name="role"
          label="Роль"
          value={formValue.role}
          onChange={changeFormValue}
          error={setError('role')}
          items={[{ value: 'admin', label: 'Администратор' }, { value: 'user', label: 'Пользователь' }]}
        />

        <FieldLongTextBlock
          name="welcomeText"
          label="Текст в блоке приветствия"
          value={formValue.welcomeText}
          onChange={changeFormValue}
          error={setError('welcomeText')}
        />

        <FieldLongTextBlock
          name="topPanelText"
          label="Текст в верхнем блоке"
          value={formValue.topPanelText}
          onChange={changeFormValue}
          error={setError('topPanelText')}
        />

        <FieldLongTextBlock
          name="bottomPanelText"
          label="Текст в нижнем блоке"
          value={formValue.bottomPanelText}
          onChange={changeFormValue}
          error={setError('bottomPanelText')}
        />

        <ButtonSaveBlock
          loading={userState.status === 'submitting'}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonBackBlock />
      </form>
    </div>
  )
}

export { UserCreateFeature };
