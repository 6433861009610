import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { useAppSelector } from '@hooks/redux.hooks';


const MaterialDetailFeature = () => {
  const materialState = useAppSelector((state) => state.material);

  return (
    <TableContainer>
      <Table aria-label="material-detail">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Фрейм</TableCell>
            <TableCell>Пользователи</TableCell>
            <TableCell>Заглушка</TableCell>
            <TableCell>Приоритет</TableCell>
            <TableCell>Дата создания</TableCell>
            <TableCell>Дата обновления</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {materialState.current!.id}
              </TableCell>
              <TableCell>{materialState.current!.name}</TableCell>
              <TableCell>
                {materialState.current!.type === 'frame' ? (
                  <a rel="noreferrer" target="_blank" href={'/' + materialState.current!.id}>Фрейм</a>
                ) : (
                  <a rel="noreferrer" target="_blank" href={materialState.current!.frameSrc}>Ссылка</a>
                )}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {materialState.current!.users.map((user) => (<Chip key={user.id} label={user.name} />))}
                </Box>
              </TableCell>
              <TableCell>{materialState!.current?.plug ? 'Да' : 'Нет'}</TableCell>
              <TableCell>{materialState!.current?.priorityOrder}</TableCell>
              <TableCell>{dateTranslate(materialState.current!.createdAt)}</TableCell>
              <TableCell>{dateTranslate(materialState.current!.updatedAt)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { MaterialDetailFeature };
