import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonBackBlock } from '@blocks/button-back';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import styles from './material-create.module.scss';
import { FieldBooleanBlock } from 'src/app/@blocks/field-boolean';
import { createMaterialAction } from 'src/app/@stores/material/material.actions';
import { MaterialType } from 'src/app/@type/material/material.type';
import { FieldMultiselectBlock } from 'src/app/@blocks/field-multiselect';
import { FieldNumberBlock } from 'src/app/@blocks/field-number';
import { FieldSelectBlock } from 'src/app/@blocks/field-select';


const MaterialCreateFeature = () => {
  const [formValue, setFormValue] = useState({
    name: '',
    frameSrc: '',
    coverSrc: '',
    type: '',
    plug: false,
    priorityOrder: '0',
    users: [],
  });
  const [formDirty, setFormDirty] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [materialState, userState] = useAppSelector((state) => [state.material, state.user]);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(createMaterialAction(formValue));

    if (result.type === '@@material/create/fulfilled') {
      navigate('/material-list/material-' + (result.payload as MaterialType).id);
    }
  }

  const setError = (fieldName: string) => {
    return materialState.errors && materialState.errors[fieldName] && errorTranslate(materialState.errors[fieldName][0]);
  }
  
  return (
    <div className={styles['user-create']}>
      <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
        <FieldTextBlock
          name="name"
          label="Название"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
        />

        <FieldSelectBlock
          name="type"
          label="Тип"
          value={formValue.type}
          onChange={changeFormValue}
          error={setError('type')}
          items={[
            { label: 'Фрейм', value: 'frame' },
            { label: 'Ссылка', value: 'link' },
          ]}
        />

        <FieldTextBlock
          name="frameSrc"
          label="Frame src"
          value={formValue.frameSrc}
          onChange={changeFormValue}
          error={setError('frameSrc')}
        />

        <FieldTextBlock
          name="coverSrc"
          label="Изображение"
          value={formValue.coverSrc}
          onChange={changeFormValue}
          error={setError('coverSrc')}
        />

        <FieldBooleanBlock
          name="plug"
          label="Заглушка"
          value={formValue.plug}
          onChange={changeFormValue}
          error={setError('coverSrc')}
        />

        <FieldMultiselectBlock
          name="users"
          label="Пользователи"
          value={formValue.users}
          onChange={changeFormValue}
          error={setError('users')}
          items={userState.list.map((item) => ({ value: String(item.id), label: `${item.name} ${item.lastName || ''}` }))}
        />

        <FieldNumberBlock
          name="priorityOrder"
          label="Приоритет сортировки"
          value={formValue.priorityOrder}
          onChange={changeFormValue}
          error={setError('priorityOrder')}
        />

        <ButtonSaveBlock
          loading={materialState.status === 'submitting'}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonBackBlock />
      </form>
    </div>
  )
}

export { MaterialCreateFeature };
