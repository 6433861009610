import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FrameStateInterface } from 'src/app/@type/frame/frame-state.inteface';
import { getMaterialListByUserAction } from './frame.actions';

const initialState: FrameStateInterface = {
  list: [],
  current: 0,
  status: 'idle'
}

const frameSlice = createSlice({
  name: '@@frame',
  initialState,
  reducers: {
    setCurrentFrame: (state, action: PayloadAction<number>) => ({
      ...state,
      current: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaterialListByUserAction.pending, (state) => {
        state.status = 'loading';
        state.list = [];
      })
      .addCase(getMaterialListByUserAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;

        if (state.current === 0) {
          state.current = action.payload.length ? action.payload[0].id : 0;
        }
      })
      .addCase(getMaterialListByUserAction.rejected, (state) => {
        state.status = 'idle';
      })
    },
});

export default frameSlice.reducer;

export const {
  setCurrentFrame,
} = frameSlice.actions;
