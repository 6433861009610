import { createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';
import { MaterialStateInterface } from 'src/app/@type/material/material-state.interface';
import { createMaterialAction, getMaterialListAction, getMaterialOneAction, updateMaterialAction } from './material.actions';


const initialState: MaterialStateInterface = {
  list: [],
  current: null,
  status: 'idle',
  errors: null,
}

const materialSlice = createSlice({
  name: '@@material',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaterialListAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getMaterialListAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getMaterialListAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getMaterialOneAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.current = null;
      })
      .addCase(getMaterialOneAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(getMaterialOneAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(createMaterialAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(createMaterialAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(createMaterialAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updateMaterialAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updateMaterialAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload
      })
      .addCase(updateMaterialAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      });
  },
});

export default materialSlice.reducer;
