import { ChangeEvent, SyntheticEvent, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardActions, CardContent, Divider } from '@mui/material';

import { FieldTextBlock } from '@blocks/field-text';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { signinAction } from '@stores/auth/auth.actions';
import { lang } from '@helpers/lang';

import styles from './signin-form.module.scss';
import { FieldPasswordBlock } from 'src/app/@blocks/field-password';


const SigninFormFeature = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const [formValue, setFormValue] = useState({ email: '', password: '' });

  const changeFormValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    dispatch(signinAction(formValue));
  };

  const setError = (fieldName: string) => {
    return auth.errors && auth.errors[fieldName] && lang(auth.errors[fieldName][0]);
  }

  return (
    <Card>
      <form onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
        <CardContent className={styles['signin-form__content']}>
            <FieldTextBlock
              label="Электронная почта"
              name="email"
              value={formValue.email}
              onChange={changeFormValue}
              error={setError('email')}
            />

            <FieldPasswordBlock
              error={setError('password')}
              label="Пароль"
              name="password"
              value={formValue.password}
              onChange={changeFormValue}
            />
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            loading={auth.status === 'submitting'}
            type='submit'
            onClick={onSubmit}
            fullWidth
            size='large'
          >Авторизация</LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
};

export { SigninFormFeature };
