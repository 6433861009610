import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';
import { CreateUserRequestInterface } from '@type/user/create-user-request.interface';
import { UpdateUserRequestInterface } from '@type/user/update-user-request.interface';
import { UserStateInterface } from '@type/user/user-state.interface';
import { UserType } from '@type/user/user.type';

import { UserApi } from './user.api';



export const getUserListAction = createAsyncThunk<
  UserType[],
  undefined,
  { state: { user: UserStateInterface } }
>(
  '@@user/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await UserApi.getList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.users;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getUserOneAction = createAsyncThunk<
  UserType,
  number,
  { state: { user: UserStateInterface } }
>(
  '@@user/get-one',
  async (id: number, { rejectWithValue, dispatch }) => {
    const response = await UserApi.getOne(id);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createUserAction = createAsyncThunk<
  UserType,
  CreateUserRequestInterface,
  { state: { user: UserStateInterface } }
>(
  '@@user/create',
  async (payload: CreateUserRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await UserApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateUserAction = createAsyncThunk<
  UserType,
  UpdateUserRequestInterface,
  { state: { user: UserStateInterface } }
>(
  '@@user/update',
  async (payload: UpdateUserRequestInterface, { rejectWithValue, dispatch }) => {
    const response = await UserApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
