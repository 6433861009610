import { HttpService } from '@services/http.service';
import { BaseResponseInterface } from '@type/shared/base-response.interface';
import { MaterialType } from 'src/app/@type/material/material.type';


export class FrameApi {
  public static async getListByUser(): Promise<BaseResponseInterface<{ materials: MaterialType[] }>> {
    return await HttpService.sendRequest('GET', '/material/list-by-user');
  };
};
