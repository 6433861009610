import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks/redux.hooks';

import { AnonGuardProps } from './anon-guard.props';


const AnonGuardComponent = ({ children }: AnonGuardProps) => {
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const fromPage = location.state?.from?.pathname || '/';

  if (auth.isLoggedIn) {
    return (<Navigate to={fromPage} />);
  }

  return children;
}

export { AnonGuardComponent }