import { TextField } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';

import { FieldTextProps } from './field-text.props';


const FieldTextBlock = ({
  value,
  onChange,
  name,
  error,
  label,
}: FieldTextProps) => {
  return (
    <FormFieldBlock error={error}>
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        variant="filled"
        fullWidth
      />
    </FormFieldBlock>
  );
};

export { FieldTextBlock };
