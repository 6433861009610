import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LayoutBaseFeature } from '@features/layout-base'
import { MaterialUpdateFeature } from '@features/material-update';
import { MaterialDetailFeature } from '@features/material-detail';
import { getMaterialOneAction } from '@stores/material/material.actions';
import { getUserListAction } from '@stores/user/user.actions';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ButtonBackBlock } from '@blocks/button-back';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';

import { MaterialDetailPageParams } from './material-detail.page-params';


const MaterialDetailPage = () => {
  const dispatch = useAppDispatch();
  const materialState = useAppSelector((state) => state.material);
  const loading = useAppSelector((state) => state.user.status === 'loading' || state.material.status === 'loading')
  const { id } = useParams<MaterialDetailPageParams>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Материалы', link: '/material-list' },
      { title: 'Материал №' + id, link: '' },
    ]));

    dispatch(getMaterialOneAction(Number(id)));
    dispatch(getUserListAction());
  }, [dispatch, id]);

  return (
    <LayoutBaseFeature>
      <CardDataBlock
        title={'Материал №' + id}
        extra={
          <>
            {isEditing
              ? (
                <ButtonCancelBlock onClick={() => setIsEditing(false)} />
              )
              : (
                <ButtonEditBlock onClick={() => setIsEditing(true)} />
              )
            }
            {!isEditing && <ButtonBackBlock />}
          </>
        }
        loading={loading}
      >
        {isEditing && (
          <TransitionOpacityBlock>
            <MaterialUpdateFeature onDone={() => setIsEditing(false)} />
          </TransitionOpacityBlock>
        )}
        {materialState.current && !isEditing && (
          <TransitionOpacityBlock>
            <MaterialDetailFeature />
          </TransitionOpacityBlock>
        )}
      </CardDataBlock>
    </LayoutBaseFeature>
  );
};

export { MaterialDetailPage };
