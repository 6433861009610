export const lang = (error: string) => {
  if (error.includes('should not be empty')) {
    return 'Поле обязательно для заполнения'
  }

  if (error.includes('must be an email')) {
    return 'Укажите корректную электронную почту'
  }

  if (error.includes('incorrect username or password')) {
    return 'Не корректные имя пользователя или пароль'
  }
};
