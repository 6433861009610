import { PersistanceService } from '@services/persistance.service';
import { BaseResponseInterface } from '@type/base-response.interface';
import { apiUrl } from 'src/config';


type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

type optionsType = {
  method: methodType;
  headers: {
    'Content-Type': string;
    'Authorization'?: string;
  };
  body: any;
};

export class HttpService {
  private static getOptions(method: methodType, payload: any): optionsType {
    const token = PersistanceService.getToken();

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(payload),
    };

    return options;
  };

  public static async sendRequest(
    method: methodType,
    url: string,
    payload?: any
  ): Promise<BaseResponseInterface<any>> {
    const options = this.getOptions(method, payload);

    try {
      const response = await fetch(apiUrl + url, options);
      const data = JSON.parse(await response.text())

      if (data.errors || data.message || data.statusCode) {
        return {
          success: false,
          errors: data.errors || data.message,
          data: null,
        }
      }

      return { data: { ...data }, success: true };
    } catch (error) {
      console.error(`${method}: ${url} - Failed to fetch`, error)
      return {
        success: false,
        data: null,
        errors: { server: [`${method}: ${apiUrl + url} - Failed to fetch`] },
      };
    }
  };
}
